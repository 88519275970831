<template>
  <div class="view pa24">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="item.name" :name="'first-' + index" v-for="(item, index) in typeList"
                   :key="index"></el-tab-pane>
    </el-tabs>
    <div class="d-flex" style="margin-top: 20px">
      <div>
        <el-input style="width: 200px!important;" class="w120 mb10 mr10" placeholder="名称" v-model="filterName"
                  clearable/>
        <el-select v-if="articeShow != 3" placeholder="请选择文章类型" class="mr10" v-model="filterType" clearable>
          <el-option v-for="(item) in sonList" :key="item.articleTypeId" :label="item.name"
                     :value="item.articleTypeId"/>
        </el-select>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="mr10" type="primary" @click="addWZ" v-if="articeShow == 3">+ 新增广告图</el-button>
        <el-button class="mr10" type="primary" @click="addWZ" v-else>+ 发布文章</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="name" align="center" label="名称" show-overflow-tooltip/>
        <el-table-column align="center" label="缩略图">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 40px" :src="scope.row.coverUrl" :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="typeName" align="center" label="类型" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0"
                        @input="scope.row.orderBy = scope.row.orderBy.replace(/^0(0+)|[^\d]+/g, '')"
                        placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch @change="saveStatus(scope.row)" v-model="scope.row.status" active-color="#51CDCB"
                       inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column v-if="articeShow != 3" prop="uploadTime" align="center" :render-header="renderHeader3" label="文章置顶"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch @change="saveIfPass(scope.row)" v-model="scope.row.ifTop" active-color="#51CDCB"
                       inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="
                            edit(scope.row, true);
                        bEdit = false;
                        editUpdate = true
                                                        ">编辑
            </el-button>
            <customPopconfirm confirm-button-text="确定" k cancel-button-text="取消"
                              @confirm="del(scope.row.workId)" icon="el-icon-info" icon-color="red" title="确定要删除吗？">
              <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog title="发布文章" :visible.sync="centerDialogVisible" :modal-append-to-body="false" width="50%" center
               @close="close">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
        <el-form-item :label="articeShow==3?'广告图名称':'文章名称'" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type" v-if="typeList1&&articeShow != 3">
          <el-cascader class="mr10" clearable :props="{
                        value: 'articleTypeId',
                        label: 'name',
                        children: 'articleTypeChilds',
                    }" v-model="value1" :options="typeList1" @change="fromHandleChange" placeholder="请选择文章分类">
          </el-cascader>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort" maxlength="11" min="0"
                    @input="ruleForm.sort = ruleForm.sort.replace(/^0(0+)|[^\d]+/g, '')" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="视频内容" prop="videoUrl" v-if="articeShow == 2">
          <el-upload class="avatar-uploader" :action="$store.state.uploadingUrl" :show-file-list="false"
                     accept=".mp4" :on-progress="handProgress" :on-success="handleAvatarSuccessVideo"
                     :before-upload="beforeAvatarUploadVideo">
            <video v-if="ruleForm.videoUrl" :src="ruleForm.videoUrl" width="100%" height="100%" controls>
              <source src="movie.mp4" type="video/mp4"/>
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过20M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item :label="articeShow == 2 ? '封面' : articeShow == 3?'图片' : '图片'" prop="coverImg">
          <el-upload :class="{
                        'avatar-uploader': true,
                        'avatar-coverImg': true,
                        disabled: uploadDisabled
                    }" :action="$store.state.uploadingUrl" list-type="picture-card" :on-progress="handProgress"
                     :on-success="handleAvatarSuccessCoverImg" :on-remove="handleRemove"
                     :before-upload="beforeAvatarUploadImg" :file-list="fileList" :limit="1">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip" v-if="articeShow == 1">
              图片上传比例为5:3，支持格式png、jpg、jpeg，大小不超过1M
            </div>
            <div slot="tip" class="el-upload__tip" v-if="articeShow == 2">
              图片上传比例为3:4，支持格式png、jpg、jpeg，大小不超过1M
            </div>
            <div slot="tip" class="el-upload__tip" v-if="articeShow == 3">
              图片建议上传750*300，支持格式png、jpg、jpeg，大小不超过1M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文章置顶" prop="ifTop" v-if="articeShow != 3">
          <el-switch v-model="ruleForm.ifTop" active-color="#51CDCB" inactive-color="#dadde5">
          </el-switch>
        </el-form-item>
        <el-form-item label="内容" prop="details" v-if="articeShow != 3">
          <quill-editor @ready="ready($event)" style="height: 100%" v-model="ruleForm.details" ref="myQuillEditor"
                        :options="editorOption" @change="onEditorChange($event)"/>
          <span class="wordNumber">{{ TiLength }}/8000</span>
        </el-form-item>

        <el-form-item label="跳转链接" v-if="articeShow == 3" prop="skipLink">
          <el-radio-group v-model="ruleForm.skipLink">
            <el-radio :label="0">跳转小程序内部页面</el-radio>
            <el-radio :label="1">跳转到网页</el-radio>
            <el-radio :label="2">跳转到外部小程序页面</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="articeShow == 3&&ruleForm.skipLink == 0" style="position: relative">
          <el-select
              clearable
              v-model="ruleForm.link"
              style="width: 100%"
              placeholder="请选择内部页面"
          >
            <el-option
                v-for="(item) in linkList"
                :key="item.linkId"
                :label="item.linkTitle　+ '　' + item.linkName"
                :value="item.linkId"
            />
          </el-select>
          <!--          <span style="position: absolute;right: 2px;top:2px;background: #FFFFFF;height: 30px;width: 100px;text-align: center;cursor: pointer">选择</span>-->
        </el-form-item>
        <el-form-item v-if="articeShow == 3&&ruleForm.skipLink == 1" style="position: relative">
          <el-input clearable v-model="ruleForm.link" placeholder="请输入URL..." maxlength="255" show-word-limit></el-input>
        </el-form-item>
        <el-form-item v-if="articeShow == 3&&ruleForm.skipLink == 2" label="小程序APPID">
          <el-input clearable v-model="ruleForm.appId" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item v-if="articeShow == 3&&ruleForm.skipLink == 2" label="跳转路径">
          <el-input clearable v-model="ruleForm.link" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="determine" v-if="bdetermine">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  lineHeightStyle
} from "@/utils/lineHeight.js";
import {
  quillEditor,
  Quill
} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {
    ImageDrop
} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'

import commonTable from "@/components/common/commonTable";
import {
  mapState
} from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  addWork,
  upWork,
  delWork,
  selectWorkList,
  selectArticleTypeList,
  upWorkTop,
  queryArticleTypeUse
} from "@/api/content";

export default {
  name: 'contentList',
  data() {
    return {
      linkList:[
        {linkId:2,linkName:'/pages/home/ruhui/initiation',linkTitle:'我要入会'},
        {linkId:3,linkName:'/pages/home/helpDonation',linkTitle:'赞助捐赠'},
        {linkId:4,linkName:'/pages/home/goodMembers',linkTitle:'优秀会员'},
        {linkId:5,linkName:'/pages/hzfc/index',linkTitle:'会长风采'},
        {linkId:6,linkName:'/pages/home/afficheList',linkTitle:'公告列表'},
        {linkId:7,linkName:'/pages/home/messageList',linkTitle:'资讯列表'},
        {linkId:8,linkName:'/pages/addressBook/index/main',linkTitle:'通讯录'},
        {linkId:9,linkName:'/pages/associationMy/informMsg',linkTitle:'消息通知'},
        {linkId:10,linkName:'/pages/associationMy/myPublish',linkTitle:'我的发布'},
        {linkId:11,linkName:'/pages/associationMy/myDating',linkTitle:'我的约局'},
        {linkId:12,linkName:'/pages/associationMy/payWill',linkTitle:'会费缴纳'},
        {linkId:13,linkName:'/pages/associationMy/feedBack',linkTitle:'意见反馈'},
        {linkId:14,linkName:'/pages/associationMy/aboutUs',linkTitle:'关于我们'},
      ],
      activeName: 'first-0',
      TiLength: 0,
      editorOption: quillConfig,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate: true,
      isUpdate: true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link: '',
        coverImg: '',
        videoUrl: '',
        appId: '',
        type: '',
        ifTop: false,
        desc: '',
        details: "",
      },
      rules: {
        title: [{
          required: true,
          message: "请输入文章名称",
          trigger: "blur"
        }],
        coverImg: [{
          required: true,
          message: "请上传图片"
        }],
        sort: [{
          required: true,
          message: "请输入排序",
          trigger: "blur"
        }],
        details: [{
          required: true,
          message: "请输入内容",
          trigger: "blur"
        }],
        // desc: [{
        // 	required: true,
        // 	message: "请输入简介",
        // 	trigger: "blur"
        // }],
        ifTop: [{
          required: true,
          message: "请选择是否置顶"
        }],
        isState: [{
          required: true,
          message: "请选择状态"
        }],
        skipLink: [{ required: true, message: "请选择跳转链接"}],
        type: [{
          required: true,
          message: "请选择类型"
        }],
        videoUrl: [{
          required: true,
          message: "请上传视频内容"
        }]
      },
      jurisdictionA: '',
      jurisdictionB: '',
      typeList: [],
      typeList1: [],
      workId: '',
      articeShow: 1,

      filterName: '',
      filterType: '',
      value: [],
      value1: [],
      srcList: [],
      articleTypeId: '',
      articleSonTypeId: '',
      sonList: [],
      fatherIdAll: ''
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  mounted() {
    this.getTypeList()
  },
  methods: {
    addWZ(){
      this.centerDialogVisible = true;
      this.bdetermine = true;
      this.editUpdate = false;
      this.value1 = []
    },
    handleChange(e) {
      this.filterType = e[e.length - 1]
    },
    renderHeader3(h, {
      column
    }) {
      return h('div', [
        h('span', column.label),
        h('el-tooltip', {
              undefined,
              props: {
                undefined,
                effect: 'dark',
                placement: 'top',
                content: '' //不需要换行就直接写在这里
              },
            },
            [ //新加一个div才能换行,不需要换行可以直接在上面加content
              h('div', {
                    slot: "content",
                  },
                  ['置顶优先级最高']
              ),
              h('i', {
                undefined,
                class: 'el-icon-question',
                style: "color:#474747;margin-left:5px;cursor:pointer;"
              })
            ],
        )
      ]);
    },
    quanbu() {
      if (this.filterType == 'all') {
        console.log('000')
        this.filterType = ''
        this.queryPage();
      }
    },
    ready() {
      Quill.register({
        'formats/lineHeight': lineHeightStyle
      }, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    userTypeList(row) {
      return row.jumpType == 3 ? '' : row.url
    },
    async getTypeList() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 1,
        status: 0,
        fatherId: 0,
      };
      try {
        const result = await queryArticleTypeUse(data);
        this.articleTypeId = result.data[0].articleTypeId
        this.typeList = result.data
        if (this.typeList[0].identificaType == 4) {
          this.articeShow = 2;
        } else if (this.typeList[0].identificaType == 5) {
          this.articeShow = 3;
        } else {
          this.articeShow = 1;
        }
        this.typeList1 = result.data;
        this.fatherIdAll = 1
        this.queryPage();
        this.getSonTypeList()
      } catch (error) {
        this.loading = false;
      }
    },
    async getTypeList1() {
      this.typeList1 = []
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 1,
        status: 0,
        articleTypeId: this.articleTypeId
      };
      try {
        let a = [{
          value: 'all',
          label: '全部',
          name: '全部',
          articleTypeId: 'all'
        }]
        const result = await selectArticleTypeList(data);
        for (let i in result.data) {
          a.push(result.data[i])
        }
        this.typeList1 = result.data;
      } catch (error) {
        this.loading = false;
      }
    },
    async getSonTypeList() {
      this.sonList = []
      this.filterType = ''
      this.articleSonTypeId = ''
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 1,
        status: 0,
        fatherId: this.articleTypeId,
      };
      try {
        const result = await queryArticleTypeUse(data);
        if (result.data) {
          // this.filterType = result.data[0].articleTypeId
          this.articleSonTypeId = result.data[0].articleTypeId
          this.sonList = result.data
        }
        this.getTypeList1()
      } catch (error) {
        this.loading = false;
      }
    },
    //选择分类
    chooseType(item) {
      let index = null
      for (let i in this.typeList1) {
        if (this.typeList1[i].articleTypeId == item) {
          index = i
        }
      }
      if (index == 1) {
        this.articeShow = 2
      } else {
        this.articeShow = 1
      }
    },
    fromHandleChange(e) {
      console.log(e)
      this.typeList1.forEach((item, index) => {
        if (item.articleTypeId == e[0]) {
          if (item.identificaType == '4') {
            this.articeShow = 2
          } else {
            this.articeShow = 1
          }
        }
      })
      this.ruleForm.type = e[e.length - 1]
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        type: 1,

        ...payload
      };
      if (this.fatherIdAll) {
        data.fatherIdAll = this.fatherIdAll
        data.classType = this.articleTypeId
      } else {
        data.fatherIdAll = ''
        // data.classType = this.articleSonTypeId
      }
      try {
        this.loading = true;
        const result = await selectWorkList(data);
        this.loading = false;
        const {
          total,
          list
        } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].coverUrl) {
            a.push(list[i].coverUrl)
          }
          if (list[i].status == 0) {
            list[i].status = true
          } else {
            list[i].status = false
          }
          list[i].ifTop = list[i].ifTop == 0 ? true : false;
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      this.ruleForm.coverImg = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        name: this.filterName,
        classType: this.filterType
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        name: this.filterName,
        classType: this.filterType
      };
      this.queryPage(data);
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      console.log(data)
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.sort = data.orderBy;
      this.ruleForm.isState = data.status ? 0 : 1;
      this.ruleForm.ifTop = data.ifTop
      if (data.bigClassType) {
        this.value1 = [data.bigClassType, data.classType]
      } else {
        this.value1 = [data.bigClassType]
      }
      this.ruleForm.type = data.classType
      this.ruleForm.details = data.remark
      this.fileList = [{
        url: data.coverUrl,
        uid: 1
      }];
      this.bdetermine = b;
      this.bEdit = b;
      this.workId = data.workId
      if (data.workType == 1) {
        this.articeShow = 2
        this.ruleForm.coverImg = data.coverUrl
        this.ruleForm.videoUrl = data.url
      }else if ( data.workType == 2 ) {
        this.articeShow = 3
        this.ruleForm.skipLink = data.jumpType;
        this.ruleForm.link = data.url;
        this.ruleForm.appId = data.appId;
      }
      this.ruleForm.coverImg = data.coverUrl
    },
    /**@method 提交 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name: this.ruleForm.title,
              orderBy: this.ruleForm.sort,
              status: this.ruleForm.isState,
              classType: this.ruleForm.type ? this.ruleForm.type : this.articleTypeId,
              ifTop: this.ruleForm.ifTop ? 0 : 1,
              remark: this.ruleForm.details,
              type: 1,
              coverUrl: this.ruleForm.coverImg,
            };
            if (this.articeShow == 2) {
              data.jumpType = 3
              data.url = this.ruleForm.videoUrl
              data.workType = 1;
            }else if (this.articeShow == 3) {
              data.workType = 2
              data.jumpType = this.ruleForm.skipLink
              data.url = this.ruleForm.link
            } else {
              data.jumpType = 1
              data.workType = '';
            }
            if (this.ruleForm.skipLink == 0) {
              for (let i in this.linkList) {
                if (this.linkList[i].linkId == this.ruleForm.link) {
                  data.url = this.linkList[i].linkName
                }
              }
              if (data.url == '') {
                this.$message({
                  message: "请选择内部链接",
                  type: "error",
                });
              }
            }
            if (this.ruleForm.skipLink == 2) {
              data.appId = this.ruleForm.appId
            }
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              var patrn = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
              if (patrn.exec(data.classType)) {
                for (let i in this.typeList) {
                  if (data.classType == this.typeList[i].name) {
                    data.classType = this.typeList[i].articleTypeId
                  }
                }
              }
              data.workId = this.workId
              await upWork(data);
            } else {
              await addWork(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delWork({
          workId: val
        });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        skipLink: 0,
        link: '',
        coverImg: '',
        appId: '',
        type: '',
        ifTop: true,
        details: '',
        // desc: ''
      }),
      this.fileList = []
      this.$refs.ruleForm.clearValidate();
      this.centerDialogVisible = false;
    },
    async saveSort(row) {
      if (row && row.sort != "") {
        let ajax = {
          workId: row.workId,
          orderBy: row.orderBy
        }
        const result = await upWork(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name: this.filterName,
          classType: this.filterType
        };
        await this.queryPage(data);
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row) {
      if (row) {
        let ajax = {
          workId: row.workId,
          status: row.status ? 0 : 1
        }
        const result = await upWork(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name: this.filterName,
          classType: this.filterType
        };
        await this.queryPage(data);
      } else {
        this.$message({
          type: "error",
          message: "状态不能为空",
        });
      }
    },
    async saveIfPass(row) {
      if (row) {
        let ajax = {
          workId: row.workId,
          ifTop: row.ifTop ? 0 : 1,
          associationId: localStorage.getItem("associationId"),
        }
        const result = await upWorkTop(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name: this.filterName,
          classType: this.filterType
        };
        await this.queryPage(data);
      } else {
        this.$message({
          type: "error",
          message: "不能为空",
        });
      }
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.videoUrl = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 20MB!");
      }
      return isLt2M;
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },


    filterNames() {
      if (this.filterType) {
        this.fatherIdAll = ''
      } else {
        this.fatherIdAll = 1
      }
      this.currentPage = 1;
      if (this.filterType == 'all') {
        this.filterType = ''
      }
      let data = {
        name: this.filterName,
        classType: this.filterType
      };
      this.queryPage(data);
    },
    handleClick(tab) {
      this.currentPage = 1;
      this.fatherIdAll = 1
      this.tableData = []
      const {name, index} = tab;
      let itemType = this.typeList[index].identificaType;
      if (itemType == 4) {
        this.articeShow = 2;
      } else if (itemType == 5) {
        this.articeShow = 3;
      } else {
        this.articeShow = 1;
      }
      let str = name.split("-")[1]
      for (let i in this.typeList) {
        if (i == str) {
          this.articleTypeId = this.typeList[i].articleTypeId
          this.getSonTypeList()
          this.queryPage()
        }
      }
      this.activeName = name;
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}</style>
